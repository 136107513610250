import React from "react";
import { BrowserRouter as Router, Switch, Route, BrowserRouter } from "react-router-dom";
import loadable from "./component/Common/loader/loadable";
import Loading from "./component/Common/loader";
import pMinDelay from "p-min-delay";

// All Page Lazy Import
const Furniture = loadable(() => pMinDelay(import("./page/furniture"), 250), {
  fallback: <Loading />,
});
const ShopLeftSideBar = loadable(() => pMinDelay(import("./page/shop/shop-left-sidebar"), 250), { fallback: <Loading /> });
const ProductDetails = loadable(() => pMinDelay(import("./page/product/index"), 250), { fallback: <Loading /> });
const About = loadable(() => pMinDelay(import("./page/about"), 250), {
  fallback: <Loading />,
});
const Error = loadable(() => pMinDelay(import("./page/error"), 250), {
  fallback: <Loading />,
});

const ContactOne = loadable(() => pMinDelay(import("./page/contact"), 250), {
  fallback: <Loading />,
});
const UploadImages = loadable(() => pMinDelay(import("./page/vendor"), 250), {
  fallback: <Loading />,
});
const UploadFile = loadable(() => pMinDelay(import("./page/vendor/UploadData"), 250), {
  fallback: <Loading />,
});
const ScrollToTop = loadable(() => pMinDelay(import("./component/Common/ScrollToTop"), 250), { fallback: <Loading /> });

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Furniture} />
            <Route path="/shop" exact component={ShopLeftSideBar} />
            <Route path="/product-details-one/:id" exact component={ProductDetails} />
            <Route path="/about" exact component={About} />
            <Route path="/contact-one" exact component={ContactOne} />
            <Route path="/uploadimages" exact component={UploadImages} />
            <Route path="/adminDataUpload" exact component={UploadFile} />
            <Route exact component={Error} />
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  );
};

export default App;
